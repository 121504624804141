import { initContract } from "@ts-rest/core";
import { z } from "zod";

const c = initContract();

const ApiKeyResponse = z.object({
  id: z.string(),
  name: z.string(),
  key: z.string(),
  createdAt: z.string(),
  expiresAt: z.string().nullable(),
  lastUsedAt: z.string().nullable(),
});

export const apiKeyContract = c.router({
  create: {
    method: "POST",
    path: "/api-keys",
    responses: {
      200: z.object({
        id: z.string(),
        name: z.string(),
        key: z.string(),
        createdAt: z.string(),
        expiresAt: z.string().nullable(),
      }),
    },
    body: z.object({
      name: z.string(),
      expiresAt: z.string().nullable(),
    }),
    summary: "Create a new API key",
  },

  list: {
    method: "GET",
    path: "/api-keys",
    responses: {
      200: z.array(ApiKeyResponse),
    },
    summary: "List all API keys for the authenticated user",
  },

  delete: {
    method: "DELETE",
    path: "/api-keys/:id",
    responses: {
      200: z.object({
        message: z.string(),
      }),
      404: z.object({
        error: z.string(),
      }),
    },
    pathParams: z.object({
      id: z.string(),
    }),
    body: z.object({
      id: z.string(),
    }),
    summary: "Delete an API key",
  },
});
