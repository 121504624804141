import { initContract } from "@ts-rest/core";
import { coverageContract } from "./coverage";
import { publicationsContract } from "./publications";
import { clientsContract } from "./clients";

const c = initContract();

export const publicApiContract = c.router({
  public: {
    coverage: coverageContract,
    publications: publicationsContract,
    clients: clientsContract,
  },
});
