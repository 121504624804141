import { z } from "zod";
import { initContract } from "@ts-rest/core";

const c = initContract();

export const PublicationSchema = z
  .object({
    id: z.string().describe("The unique identifier for the publication"),
    name: z.string().describe("The name of the publication."),
    tier: z
      .enum(["TIER1", "TIER2", "TIER3"])
      .describe(
        "The tier of the publication - 1 is a top tier publication (Bloomberg, Reuters, etc.), 2 is a mid tier publication, and 3 is a low tier publication (smaller blogs, etc.)."
      ),
    imageUrl: z
      .string()
      .nullable()
      .describe("A link to the logo of the publication."),
    domain: z
      .string()
      .describe("The domain of the publication, excluding the www."),
    country: z
      .string()
      .nullable()
      .describe(
        "The country where the publication is based. (ISO 3166-1 alpha-2)"
      ), // ISO 3166-1 alpha-2
    readership: z
      .number()
      .describe("The average monthly readership of the publication."),
  })
  .describe("The publication that the coverage piece was published in.");

const SearchPublicationsQuerySchema = z.object({
  skip: z
    .number({ coerce: true })
    .optional()
    .describe("The number of items to skip."),
  take: z
    .number({ coerce: true })
    .optional()
    .describe("The number of items to take."),
  search: z
    .string()
    .optional()
    .describe(
      "The search query. Limits publications to those with titles that match the search query."
    ),
  country: z
    .string()
    .optional()
    .describe("The country to filter by. (ISO 3166-1 alpha-2)"),
  tier: z
    .enum(["TIER1", "TIER2", "TIER3"])
    .optional()
    .describe("The tier to filter by."),
});

export const publicationsContract = c.router({
  get: {
    method: "GET",
    path: "/public/publications",
    query: SearchPublicationsQuerySchema,
    description: `Search all publications in the platform. This endpoint is paginated, with a default page size of 100 and maximum page size of 1000.
      Example call: <pre><code>curl -X GET 'https://api.yaarn.ai/public/publications?skip=0&take=100&search=tech&country=US&tier=TIER1' -H 'Authorization: Bearer YOUR_API_TOKEN'</code></pre> (where YOUR_API_TOKEN is your API key)`,
    responses: {
      200: z.array(PublicationSchema),
      403: z.object({
        message: z.string().describe("You are not authorized."),
      }),
    },
  },
});
