import { initContract } from "@ts-rest/core";
import { z } from "zod";

const c = initContract();

const CompetitorSchema = z.object({
  id: z.string().describe("The unique identifier for the competitor"),
  name: z.string().describe("The name of the competitor"),
  imageUrl: z
    .string()
    .nullable()
    .describe("A link to the logo of the competitor."),
});

export const ClientSchema = z
  .object({
    id: z.string().describe("The unique identifier for the client"),
    name: z.string().describe("The name of the client organization"),
    imageUrl: z
      .string()
      .nullable()
      .describe("A link to the logo of the client."),
    competitors: z
      .array(CompetitorSchema)
      .describe("The competitors of the client."),
  })
  .describe("A client organization that uses the platform.");

export const clientsContract = c.router({
  get: {
    method: "GET",
    path: "/public/clients",
    description:
      "Get all clients that the authenticated user has access to. Example call: <pre><code>curl -X GET 'https://api.yaarn.ai/public/clients' -H 'Authorization: Bearer YOUR_API_TOKEN'</code></pre> (where YOUR_API_TOKEN is your API key)",
    responses: {
      200: z.array(ClientSchema),
      403: z.object({
        message: z.string().describe("You are not authorized."),
      }),
    },
  },
});
