import { initContract } from "@ts-rest/core";
import { aiContract } from "./contracts/aiContract";
import { adminAuthContract } from "./contracts/adminAuthContract";
import { authenticationContract } from "./contracts/authenticationContract";
import { fileContract } from "./contracts/fileContract";
import { miscContract } from "./contracts/miscContract";
import { signupContract } from "./contracts/signupContract";
import { apiKeyContract } from "./contracts/apiKeys";
import { publicApiContract } from "./contracts/public-api";

const c = initContract();

export const contract = c.router({
  ai: aiContract,
  admin: adminAuthContract,
  auth: authenticationContract,
  files: fileContract,
  misc: miscContract,
  signup: signupContract,
  keys: apiKeyContract,
  public: publicApiContract,
});
